var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticClass: "w-4",
      attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 16 16" },
    },
    [
      _c("title", [_vm._v("Publisher")]),
      _c("g", { attrs: { id: "Capa_2", "data-name": "Capa 2" } }, [
        _c("g", { attrs: { id: "Capa_1-2", "data-name": "Capa 1" } }, [
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M13.74,5h-.07v6.53a.67.67,0,0,1-.68.68h-9a.68.68,0,\n        0,0,.69.68h9.11a.67.67,0,0,0,.68-.68V5.69A.67.67,0,0,0,13.74,5Z",
            },
          }),
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M15.32,6.59h-.07v6.53a.67.67,0,0,1-.68.68h-9a.68.68,\n        0,0,0,.68.68h9.12A.67.67,0,0,0,16,13.8V7.27A.67.67,0,0,0,15.32,6.59Z",
            },
          }),
          _c("rect", {
            staticClass: "fill-current",
            attrs: {
              x: "3.41",
              y: "6.47",
              width: "4.07",
              height: "0.49",
              rx: "0.17",
            },
          }),
          _c("rect", {
            staticClass: "fill-current",
            attrs: {
              x: "8.13",
              y: "6.47",
              width: "3.34",
              height: "3.45",
              rx: "0.17",
            },
          }),
          _c("rect", {
            staticClass: "fill-current",
            attrs: {
              x: "3.41",
              y: "7.95",
              width: "4.07",
              height: "0.49",
              rx: "0.17",
            },
          }),
          _c("rect", {
            staticClass: "fill-current",
            attrs: {
              x: "3.41",
              y: "9.43",
              width: "4.07",
              height: "0.49",
              rx: "0.17",
            },
          }),
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M12.18,3.52H2.68A.67.67,0,0,0,2,4.2v6.53a.67.67,0,0,\n        0,.68.68h9.5a.67.67,0,0,0,.68-.68V4.2A.67.67,0,0,0,12.18,\n        3.52Zm-6.06.53a.42.42,0,0,1,.42.42.41.41,0,0,1-.42.42.42.42,\n        0,0,1-.42-.42A.42.42,0,0,1,6.12,4.05Zm-1.48,0a.42.42,0,0,1,\n        .42.42.42.42,0,0,1-.42.42.41.41,0,0,1-.42-.42A.42.42,0,0,1,\n        4.64,4.05Zm-1.49,0a.42.42,0,0,1,.42.42.42.42,0,0,1-.42.42.41.41,\n        0,0,1-.42-.42A.42.42,0,0,1,3.15,4.05Zm8.9,6.55H2.81V5.48h9.24Z",
            },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }